@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
body {
  background-color: white;
  margin: auto;
  padding: 0px;
  overflow-x: hidden;
  background-repeat: repeat;
  font-family: 'Inter', sans-serif;
  display: grid;
}

@media (max-width:600px) { 
  .mobile-hide{
    display: none;
  }
  .qrscanner{
    width: 100% !important;
  }

}

.qrscanner{
  width: 40%;
}
.margin-0-auto{
  margin: 0 auto;
}
.container {
  width: 100%;
	padding-right:15px;
	padding-left:15px;
	margin-right:auto;
	margin-left:auto
}
img {
  max-width: 100%;
  height: auto;
}

.sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 60px 0;
}
.sec__one h1{
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #07a8ff;
  font-family: 'Inter', sans-serif;
}
.navBar .logo img {
  float: left;
  max-height: 120px;
}
.is-active {
  color: #ff5800!important;
}

.navBar .col-md-3 {
  z-index: 7;
}
ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}
ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 23px;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  position: relative;
  display: block;
  color: #545050;
  margin: 27px 17px;
  text-decoration: none;
  cursor: pointer;
}
.header__middle__logo{
  width: 15%;
  display: flex;
  float: left;
  justify-content:center;
  align-items:center;
}
.header__middle__menus {
  width: 80%;
  display: inline-block;
  flex-direction: column-reverse;
}
.menu-item .menu__qqqitem a{
  margin: 27px 10px;
}
.header__middle__search {
  width: 7%;
  display: inline-block;
  /* float: right; */
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}
.header__middle {
  display: flex;
  width: 100%;
  float: left;
  /* border-bottom: 1px dashed lavender; */
  position: relative;
}
.header__middle__logo > a > h1 {
  color: #07a8ff;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 10px;
}
/* .active{
  color: #ff1800!important;
} */
.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 10px;
  color: #ff1800;
}
.sub__menus__arrows{
  position: relative;
}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid lavender;
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}

@media (max-width:767px) { 
  .header__middle .active {color: #ff3d00!important;}
}

@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus  a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
}
.menu-item:hover > a{
  color: #07a8ff !important;
}
.menu-item .sub__menus li:hover a{
  color: #07a8ff !important;
}

.header__middle__logo img{
  max-width: 207px;
  margin: 17px 0 0 0;
}

@media(max-width:580px){
  svg {
    vertical-align: middle;
    width: 98%;
  }
}
@media(max-width:991px){
.header__middle__logo {width: 50%;}
.header__middle__menus {width: 50%;}
.header__middle__logo img {max-width: 197px;margin: 27px 0 0 0;}
.main-nav .menubar__button:hover {background-color: #f44336;}
.main-nav .menu-item:hover > a {color: #07a8ff !important}
.header__middle__logo {
  
  padding-top: 30px;
}
.main-nav .menubar__button {
  display: block!important;
  float: right;
  background-color: #080808;
  color: #fff;
  padding: 6px 7px;
  border-radius: 5px;
  margin: 27px auto 0;
  cursor: pointer;
  position: relative;
  z-index: 10037;
}

.main-nav .menubar__button svg{font-size: 27px;}
.main-nav ul.menuq2 {
  display: block!important;
  position: absolute;
  left: 0;
  z-index: 10007;
  background-color: #d8f1ff;
  border-bottom: 3px solid #ff3d00;
  right: 0;
  padding-bottom: 17px;
  padding-top: 57px;
}
.main-nav .menu-item a {margin: 10px 17px;text-align: center;color: #000000;}
.main-nav  ul.main-menu {display: none}
.main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}

}


a{
  list-style: none;
  text-decoration: none;
}

.loader{
  position: absolute;
  left: 49%;
  top: 50%;
}

.contact .info{
  width: 100%;
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #3f43fd;
  float: left;
  width: 44px;
  height: 44px;
  background: #f0f0ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #010483;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #0205a1;
}

.contact .info .email, .contact .info .phone {
  margin-top: 40px;
}

.contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
  background: #1acc8d;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}


.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

/* .contact .php-email-form button[type="submit"] {
  background: #1acc8d;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #34e5a6;
} */

#scanner{
  width: 93%;
  background: #3f43fd;
  border: 0;
  padding: 10px 20px;
  color: white;
  border-radius: 40px;
}

#scanner:hover{
  background: #0205a1;
}

#verify{
  width: 93%;
}

#hash-input{
  border-radius: 40px;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.section-title {
  padding: 160px 0px 20px 0px;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #010483;
}

a{
  list-style: none;
  text-decoration: none;
}

.loader{
  position: absolute;
  left: 49%;
  top: 50%;
}

.contact .info {
  width: 100%;
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #3f43fd;
  float: left;
  width: 44px;
  height: 44px;
  background: #f0f0ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #010483;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #0205a1;
}

.contact .info .email, .contact .info .phone {
  margin-top: 40px;
}

.contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
  background: #1acc8d;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}


.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  background: #46C668;
  border: 0;
  padding: 10px 40px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
  justify-content: center;
  font-weight: bold;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #18d26e;
}

#scanner{
  width: 89%;
  background: #2581F3;
  border: 0;
  padding: 10px 20px;
  color: white;
  border-radius: 40px;
  margin-left: 12px;
  margin-top: -20px;
  font-weight: bold;
}

#scanner:hover{
  background: #3f43fd;
}

/* .scanner-x{
  margin-top: -10px;
} */

#hash-input{
  border: 1px solid gray;
  border-radius: 40px;
  width: 95%;
  margin-top: -12px ;
  text-align: center;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.section-title {
  padding: 90px 0px 10px 0px;
}

.section-title p {
  margin: 0;
  margin-right: 25px;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  text-transform: unset;
  font-family: "Poppins", sans-serif;
  color: black;
}

::placeholder{
  color: #918d8d;
  opacity: 2;
}

#logo-hero{
  height: 450px;
  width: 450px;
  padding: 150px;
}

#breaker{
  text-align: center;
}

#vbtn{
  margin: auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

}

#veri{
  margin: 0;
  /* margin-right: 25px; */
  text-align: center;
  font-size: 13px;
  /* font-weight: 700; */
  text-transform: unset;
  font-family: "Poppins", sans-serif;
  color: black;
  /* padding-left: 139px; */
  display: flex;
  justify-content: center;
  padding: 30px;
}

#faqs{
  margin: 0;
  /* margin-right: 25px; */
  text-align: center;
  font-size: 13px;
  /* font-weight: 700; */
  text-transform: unset;
  font-family: "Poppins", sans-serif;
  color: black;
  /* padding-left: 139px; */
  display: flex;
  justify-content: center;
  margin-top: -25px;
}

.working-title {
  padding-bottom: 20px;
  padding-top: 100px;
}

.working-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}

.working-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #1acc8d;
  margin: 4px 10px;
}

.working-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #010483;
}

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #5fcf80;
}

.about .content .learn-more-btn {
  background: #5fcf80;
  color: #fff;
  border-radius: 50px;
  padding: 8px 25px 9px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
}

.about .content .learn-more-btn:hover {
  background: #3ac162;
  color: #fff;
}

@media (max-width: 768px) {
  .about .content .learn-more-btn {
    margin: 0 48px 0 0;
    padding: 6px 18px;
  }
}

.scan-img{
  /* margin: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 80px;
  margin-top: -200px;
}

.verify-heading{
  /* background-color: #2581F3; */
  /* font-weight: 600;
  font-size: 26px; */
  /* margin: 0; */
  /* margin: 80px; */
  margin-bottom: -20px;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #010483;
}

.credential{
  color: white;
  background-color: #2581F3;
  /* padding-top: 0px; */
  padding: 0px 100px;
}
/* 
#example-certi{
  height: 300px;
  width: 500px;
} */

.logo-img{
  height: 20px;
}